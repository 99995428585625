/*
 * id="menu"のリストを横並びに表示した時、それぞれの要素の高さを揃える
 *
 * https://into-the-program.com/auto-height/ を参照
 */

import 'core-js/stable';
import 'regenerator-runtime/runtime';

const menuAutoHeight = () => {
  let elems = document.querySelectorAll('ul#menu li'); // cssが 'ul#menu li' の要素を取得
  const arraySpanA = [];  // 'ul#menu li span' または 'ul#menu li a' を格納する配列
  const heightArray = []; // elemの要素の高さを格納する配列

  // liの中の span または a の要素を得る
  elems.forEach( elem => {
    arraySpanA.push( elem.children );
  });

  // span または　aの要素の初期化と探索
  arraySpanA.forEach( spanA => {
    spanA.item(0).style.height = ''; // 初期化（リサイズ対策用）
    heightArray.push( spanA.item(0).clientHeight ); // 要素の高さを取得
  });

  // 配列に格納した高さの最大値を取得
  const maxHeight = Math.max.apply( null, heightArray );

  // 要素のheightにmaxHeightを設定
  arraySpanA.forEach( spanA => {
    spanA.item(0).style.height = maxHeight + 'px';
  });
}

// ロードとリサイズ時に関数menuAutoHeightを実行
window.addEventListener( 'load', menuAutoHeight );
window.addEventListener( 'resize', menuAutoHeight );

